<template>
  <b-sidebar
    id="sidebar-car-edit"
    :visible="isEditCarSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-car-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ `${t('Edit Car')} #${id}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
        v-show="!onSubmitProcess.open"
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          v-if="id!=null && car!=null"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Number name -->
          <validation-provider
            #default="validationContext"
            :name="t('Number')"
            rules="required|alpha-num"
          >
            <b-form-group
              :label="t('Number')"
              label-for="car-number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="car-number"
                v-model="car.number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="01A777AA"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Comments')"
            rules=""
          >
            <b-form-group
              :label="t('Comments')"
              label-for="comments"
              :state="getValidationState(validationContext)"
            >
              <b-form-textarea
                id="comments"
                v-model="car.comments"
                type="text"
                :state="getValidationState(validationContext)"
                placeholder=""
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div
            class="d-flex mt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="onSubmitProcess.save"
            >
              <b-spinner
                v-if="onSubmitProcess.save"
                small
                type="grow"
              />
              {{ t('Save') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <div
        v-show="onSubmitProcess.open"
        class="text-center text-primary my-2"
      >
        <b-spinner
          class="align-middle mr-1"
        />
        <strong>{{ t('Загрузка') }}...</strong>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import {
  ref, watch, onUnmounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import carStoreModule from './carStoreModule'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BSpinner, BFormInput, BFormInvalidFeedback, BButton, ValidationProvider, ValidationObserver, BFormTextarea,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditCarSidebarActive',
    event: 'update:is-edit-car-sidebar-active',
  },
  props: {
    isEditCarSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const { t } = useI18nUtils()

    const onSubmitProcess = ref({
      save: false,
      open: false,
    })

    const CAR_APP_STORE_MODULE_NAM = 'app-car'

    // Register module
    if (!store.hasModule(CAR_APP_STORE_MODULE_NAM)) store.registerModule(CAR_APP_STORE_MODULE_NAM, carStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAR_APP_STORE_MODULE_NAM)) store.unregisterModule(CAR_APP_STORE_MODULE_NAM)
    })
    const fields = ['number', 'name', 'status', 'comments']
    const blank = ref({
      number: '',
      comments: '',
    })

    const car = ref(JSON.parse(JSON.stringify(blank)))

    const resetuserData = () => {
      car.value = JSON.parse(JSON.stringify(blank))
    }
    watch(() => props.isEditCarSidebarActive, val => {
      // // console.log(val)
      if (val === true) {
        onSubmitProcess.value.open = true
        store.dispatch('app-car/fetchCar', {
          _where: `(id,eq,${props.id})`,
          _fields: fields.join(','),
          _tableconstruction_version: 'vertri',
          _zeTable: 'Cars',
        })
          .then(response => {
            const obj = response.data.records[0]
            car.value = obj
          })
          .catch(error => {
            if (error.response.status === 404) {
              car.value = undefined
            }
          })
          .finally(() => {
            onSubmitProcess.value.open = false
          })
      }
    })
    // // console.log(Ripple)

    const workableOptions = ref([
      'OK',
      'NOK',
    ])

    const onSubmit = () => {
      onSubmitProcess.value.save = true
      store.dispatch('app-car/updateCar', {
        id: props.id,
        carData: {
          number: car.value.number.replace(' ', ''),
          comments: car.value.comments,
        },
      })
        .then(res => {
          console.log(res.data)
          emit('refetch-data')
          emit('update:is-edit-car-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно обновили данные станции.',
            },
          })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'error',
              text: error.message,
            },
          })
        })
        .finally(() => {
          onSubmitProcess.value.save = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      onSubmitProcess,
      car,
      workableOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
