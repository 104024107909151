<template>
  <b-sidebar
    id="sidebar-car-add"
    ref="refCarSidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add Car') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Number name -->
          <validation-provider
            #default="validationContext"
            :name="t('Number')"
            rules="required|alpha-num"
          >
            <b-form-group
              :label="t('Number')"
              label-for="car-number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="car-number"
                v-model="car.number"
                :state="getValidationState(validationContext)"
                trim
                placeholder="01A777AA"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Name -->

          <validation-provider
            #default="validationContext"
            :name="t('Comments')"
            rules=""
          >
            <b-form-group
              :label="t('Comments')"
              label-for="comments"
              :state="getValidationState(validationContext)"
            >
              <b-form-textarea
                id="comments"
                v-model="car.comments"
                type="text"
                :state="getValidationState(validationContext)"
                placeholder=""
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="onSubmitProcess"
            >
              <b-spinner
                v-if="onSubmitProcess"
                small
                type="grow"
              />
              {{ t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import carStoreModule from './carStoreModule'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, ValidationProvider, ValidationObserver, BSpinner, BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const { t } = useI18nUtils()

    const refCarSidebar = ref(null)

    const CAR_APP_STORE_MODULE_NAM = 'app-car'

    // Register module
    if (!store.hasModule(CAR_APP_STORE_MODULE_NAM)) store.registerModule(CAR_APP_STORE_MODULE_NAM, carStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAR_APP_STORE_MODULE_NAM)) store.unregisterModule(CAR_APP_STORE_MODULE_NAM)
    })

    const blank = ref({
      number: '',
      comments: '',
    })

    const car = ref(JSON.parse(JSON.stringify(blank)))

    const resetuserData = () => {
      car.value = JSON.parse(JSON.stringify(blank))
    }

    const workableOptions = ref([
      'OK',
      'NOK',
    ])

    const onSubmitProcess = ref(false)
    const onSubmit = () => {
      onSubmitProcess.value = true
      store.dispatch('app-car/addCar', {
        number: car.value.number.replace(' ', ''),
        comments: car.value.comments,
      })
        .then(res => {
          console.log(res.data)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно обновили роль доступа.',
            },
          })
          emit('refetch-data')
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'error',
              text: error.message,
            },
          })
        })
        .finally(() => {
          onSubmitProcess.value = false
          refCarSidebar.value.hide()
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      car,
      workableOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      t,
      onSubmitProcess,
      refCarSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
