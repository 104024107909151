import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import router from '@/router'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCarList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refStationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'number', label: 'Номер', sortable: true },
    { key: 'comments', label: 'комментарии', sortable: true },
    { key: 'actions', label: 'Действия' },
  ]
  const perPage = ref(10)
  const totalStations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refStationListTable.value ? refStationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStations.value,
    }
  })

  const refetchData = () => {
    refStationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })
  const q = (val, joiner) => {
    const where = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tableColumns.length; i++) {
      const column = tableColumns[i].key
      if (column !== 'actions') where.push(`(${column},like,~${val}~)`)
    }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }
  const fetchCars = (ctx, callback) => {
    store
      .dispatch('app-car/fetchCars', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + sortBy.value,
        _where: (statusFilter.value !== null ? `(status,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : ''),
        _fields: '-',
        _tableconstruction_version: 'vertri',
        _zeTable: 'Cars',
      })
      .then(response => {
        const { records, total } = response.data
        // // console.log('fetching ok', stations)
        callback(records)
        totalStations.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: `Error fetching stations list: ${error}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStationStatusVariantAndIcon = status => {
    if (status === 'NOK') return { variant: 'warning', icon: 'AlertTriangleIcon' }
    if (status === 'OK') return { variant: 'success', icon: 'CheckCircleIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const deleteCar = id => {
    store.dispatch('app-car/deleteCar', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          throw new Error(response.data.status)
          // purchaseData.value = response.data
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Fail to delete',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        // purchaseData.value = undefined
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchCars,
    tableColumns,
    perPage,
    currentPage,
    totalStations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStationListTable,

    statusFilter,

    resolveStationStatusVariantAndIcon,

    refetchData,
    deleteCar,
    t,
  }
}
